import React from 'react'
import LibraryWrapper from './LibraryWrapper'
import { Button } from '../../components/library'

function button() {
    return (
        <LibraryWrapper>
            <Button variant="contained" size="small" color="primary">
                Small
            </Button>
            <hr />
            <Button variant="contained" size="medium" color="primary">
                Medium
            </Button>
            <hr />
            <Button variant="contained" size="large" color="primary">
                Large
            </Button>
            <hr />
            <Button variant="contained">Default</Button>
            <hr />
            <Button variant="contained" color="primary" inline onClick={(e) => console.warn('MY CLICK!', e)}>
                Primary With Click Event
            </Button>

            <Button variant="contained" color="secondary" inline>
                Secondary
            </Button>

            <Button variant="contained" disabled color="primary" inline>
                Disabled
            </Button>
            <hr />
            <Button to="/components" inline>
                With Gatsby Link
            </Button>
            <Button variant="outlined" inline>Default</Button>
            <Button variant="outlined" color="primary" inline>
                Primary
            </Button>
            <Button variant="outlined" color="secondary" inline>
                Secondary
            </Button>
            <Button variant="outlined" color="primary" disabled inline>
                Disabled
            </Button>
        </LibraryWrapper >
    )
}
export default button
